import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63V3WrCMBTA8XufIpCbCTqSpmnSeLs9SJo0zvrRrVP3xd59xMI4FTkc2Mjd3%2FjjNFHqhr4%2Fsq8ZY8tl0vuu3wnHuKiEsnEFqrxZC8e48sqEAKtyjFtjdUqwlo7xUIVyWrVjPOl0tbdyjLdRCCthNXmvCW00sNpck01ewVpfaivayV6fa0wqaViby95UJw9ryFV5oUpYo2M8xqapJ%2FO2jvFHqQv1AGvKZya01JOnWDvGjc4L1ifHuA95wbpxjJc2L1i7cV6YtvnACiGEWM2%2BZ%2FdjPEzudefY2Q93v9c8h9%2FfTz80c8D0GCMxpoPMM8YojJGQecGYgjzNgDEleZpXjNFk5ogxFfmhThhjyNOcMaYmM28YY8nM%2B%2F8wHxjTkJlPjAlkRgrMiXRHYk5L%2FuXIAnMS3VGYs6Y7JeY8Yc5m4mjM2dDnqTCno9%2BXwZwtfR77x3maPo7%2FiNDv%2BuHqWBb5vTW%2BIBoftuuhPx3i8sbObnF5E2XxB2qfoqNJCAAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62WzY6bMBSF93kKLxM1WP43ZlazmGVXfYCKgJO4JZABmvmp%2Bu5VCIQxXEujSbbX3I97zvFFJHVVtejvAqEo%2BkmdZhtHSYJOab2Mol37eLC1y9Kn19aWuc1X6%2BnJjzYt87Q%2BnzzWLi3WqEnLJmps7bYPHpXOqJ%2FvZTf08lnv96qsblEiEpQV6eG4ZJgyWdvDGmVpkS0jikVMVFdC35DGkrLTy2qNRP%2FcyufIgUOxmnCINgNGYsVFh%2BEwRo2YjxSCpeaG6x5DsSBxhxneNsHoEaM%2FYChmxnA6TEOw4abDsDkiDiForK62UEz1RQ%2FDBJzEjBjqK1JaQKNQfJE54aQhYzSsiGIAshkgHHuSlNE0HszlWEvVRw0wsnDQ8uzugFHnyPqoQUV5ctXqH9h7SN0GfY%2BNUSNEqAECpbcbKMS7BARLJgAIwTGkdB%2BiBG4jnSPcdU09xxWR0IrO%2B3%2BNdviZMSKA5ZwDfgf9FCKGNxNworhHtIe7RFuOocR%2BKoIFFhP8YlVBjhLgNHPEEXYlotgQAW7UnPF8ZXjXVARshZTUtytp7rIv7UhRfsAcDLh%2F2YTyJ0GCEL92SpCc1l4SpKa11wTpae0N6H1P0KRy%2FuEgD4t%2Fi02Vv3X%2FItuqbKPGvduv2lu40kZ763b79uvrU9i2tXXUHNPMlbvLjP8BBCJUtjAJAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMjRweDsKICAtLV8xYWNlYjRxMzogMjRweDsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuMTI1cmVtOwogIC0ta2RtdnFpNzogMC4yNXJlbTsKICAtLWtkbXZxaTg6IDAuMzc1cmVtOwogIC0ta2RtdnFpOTogMC41cmVtOwogIC0ta2RtdnFpYTogMC43NXJlbTsKICAtLWtkbXZxaWI6IDFyZW07CiAgLS1rZG12cWljOiAxLjI1cmVtOwogIC0ta2RtdnFpZDogMS41cmVtOwogIC0ta2RtdnFpZTogMnJlbTsKICAtLWtkbXZxaWY6IDIuMzc1cmVtOwogIC0ta2RtdnFpZzogY2xhbXAoMi4zNzVyZW0sIGNhbGMoMS4yNDgyNXJlbSArIDIuMzQ3dncpLCAzcmVtKTsKICAtLWtkbXZxaWg6IGNsYW1wKDNyZW0sIGNhbGMoMi4wOTg1NjI1cmVtICsgMS44Nzh2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlpOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS42OTcxODc1cmVtICsgMy43NTZ2dyksIDQuNXJlbSk7CiAgLS1rZG12cWlqOiBjbGFtcCgzLjVyZW0sIGNhbGMoMC43OTU3NXJlbSArIDUuNjM0dncpLCA1cmVtKTsKICAtLWtkbXZxaWs6IGNsYW1wKDVyZW0sIGNhbGMoMC40OTI5Mzc1cmVtICsgOS4zOTB2dyksIDcuNXJlbSk7Cn0%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U3W6jMBCF7%2FsU57KVQuThN8y%2BQN%2Bglyvz08RNgqkhLd3VvnsFSlrGtM1KIIbPZ84MxjY7a3v8vQGCwLqh3aeKEZBS6teMESPIJQoZJEnESCSJGakkyZQlUMqgSKKMId43jCRL22HOckaWbiTTjDwlyQoGUR5LWDIojr3sihFKUjM6ezDVnD16vW0Zap24%2BjiHuxFuMh8bBq0p9PETI1xY7Bkv2t0GwW8yWVgY6u7mwwcGeRnHsejCvBlrRotW7DK%2F9Ss%2Bi4rPyww3VlxYd77PQfj049cuJ%2BE09rmcsheGaofpTtsBblvoW7XC%2BVpTJLxfP9WkrsuHmfw%2F3N%2F4o4%2FwkuenhMnd6sM0%2FkZEifD9M%2F24Tmw4xZNOQvpCGI4skiwaWSxZPK1TyRJGocv91tlTUwWlPVi3wvlRWFfV7gKnXlDrrg5ME9hTL3zSr3x%2BTskYttWl6d%2BuCDeM3umme7TueEWaM3a12e76FY56uL%2BEprmEr6bqd9PgwzkyzTn62Vl7m54KhnekUMnwDhSqeFpXgtWMSI3s3zvXISe%2FewUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82Y3W7jIBCF7%2FMUc7NSV7IjE5I0daV9lRWxsSHhJ8W4Sbrqu6%2FabOuhW0Gq%2FKi35oNzbJjhyGO%2F96IppvBnBFBZZV0Jj8zd5PlhoMhAGsGd9D%2FvRwCNNT7fctkKH3ITzD2Pxoens9dl39d6m9LM9MqqApPzGEkweRsjJ5hcxEiKybsYOcUki5EzTC5j5ByTVYy8xWQdIxeY5DHyDpNNjGSYbGPkEpMiRlaYlDGyxuQqRnJMrmNkg0kVI1tM6hgpMGlipMSkjZErTG5i5BqTD6%2FkJ2X6m8jbyVKS%2FVDHDdNS7T8CxQB08ol%2FHJavw0oanotPBYJycZe2s0rZCWqyu7SddcpOUPj%2B0nZUyk7QXfpL29EpO0ELe7y0HZOyE%2FTJ7WDnczUSV%2FMptaDf7E5U61JqQR%2Fcn6jmUmpBf346Ue0hpRbcG6RIySWOySYlF1x9hKSObZ86tonXtyk%2FwaVNaMoPOcbPsN70LGU5rDdLrbdLrRdEOXJIkpq5Vpp8ab23uoRiPOcaU4cU6fnO594x0zXW6RL6zYa7inUco4tjNnTA71L4Y4CzFL4N8OUxX%2BsdpyC8VhksbX1UiaOJnXfWtF9Ua2TbOw6%2FQOrD1K2svSiBFMWPl018O6%2Bs9%2FYe7ZKy1Trnpi6BBNtE4avv26sM7CHWbVhdS9Pmije%2BBDJeVCJAlTzmpFAQyYq%2BbqaiICbfK1ZREMkuc91kRUGcp0%2BdLVxREMlOd918RUHMv1fEouClOTWLfC1nUeg0U%2BqqaYuC5rXs9VVDFwXFXMuvGr0o7JJf9qzhi3646b5DAHu5QDIQkwwEzUBMMxCz7K30kpcP%2B%2F%2FH3PCn61%2BAqXllHfPSmhJ6U3P3Yu1%2B9PwXrnn1RtwTAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'tythf04',variantClassNames:{color:{primaryAlt:'tythf05',primary:'tythf06',primaryTintDark:'tythf07',primaryTint:'tythf08',primaryTintLight:'tythf09',primaryTintLighter:'tythf0a',secondaryAlt:'tythf0b',secondary:'tythf0c',secondaryTintDark:'tythf0d',secondaryTint:'tythf0e',secondaryTintLight:'tythf0f',secondaryTintLighter:'tythf0g',orange:'tythf0h',sand:'tythf0i',notifyRed:'tythf0j',notifyGreen:'tythf0k',neutralDark:'tythf0l',neutralLight:'tythf0m',black:'tythf0n',white:'tythf0o',error:'tythf0p'},variant:{h1:'tythf0q',h2:'tythf0r',h3:'tythf0s',h4:'tythf0t',h5:'tythf0u',h6:'tythf0v',tiny:'tythf0w',small:'tythf0x',medium:'tythf0y',large:'tythf0z',xl:'tythf010',body:'tythf011',inherit:'tythf012'},bodyFont:{true:'tythf013'},headingFont:{true:'tythf014'},monoFont:{true:'tythf015'},autoMargin:{true:'tythf016'},uppercase:{true:'tythf017'},weight:{regular:'tythf018',medium:'tythf019',semibold:'tythf01a',bold:'tythf01b'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','variant','bodyFont','headingFont','monoFont','autoMargin','uppercase','weight']};
export var txtVars = {color:'var(--tythf00)',display:'var(--tythf01)',fontWeight:'var(--tythf02)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1i)',lineHeight:'var(--_1i72bi14)'},h2:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1j)',lineHeight:'var(--_1i72bi15)'},h3:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1k)',lineHeight:'var(--_1i72bi16)'},h4:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1l)',lineHeight:'var(--_1i72bi17)'},h5:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1m)',lineHeight:'var(--_1i72bi18)'},h6:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1n)',lineHeight:'var(--_1i72bi19)'},tiny:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1t)',lineHeight:'var(--_1i72bi1g)'},small:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1s)',lineHeight:'var(--_1i72bi1f)'},medium:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1r)',lineHeight:'var(--_1i72bi1e)'},large:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1q)',lineHeight:'var(--_1i72bi1d)'},xl:{fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1p)',lineHeight:'var(--_1i72bi1b)'},body:{fontWeight:'var(--_1i72bi1u)',fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1o)',lineHeight:'var(--_1i72bi1a)'},inherit:{}};
export var withHtml = 'tythf03';