import { gql } from '__generated__';

export const ARTICLE_INDEX_CARD_FRAGMENT = gql(`
  fragment articleIndexCard on index_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
  }
`);

export const ARTICLE_CARD_FRAGMENT = gql(`
  fragment articleCard on article_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
  }
`);

export const HOME_CARD_FRAGMENT = gql(`
  fragment homeCard on home_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }

    # Hero section
    eventMultiple {
      ...eventCard
    }
  }
`);

export const VENUE_CARD_FRAGMENT = gql(`
  fragment venueCard on venue_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
    colorScheme {
      label
    }
  }
`);

export const PAGE_STANDARD_CARD_FRAGMENT = gql(`
  fragment pageStandardCard on standard_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
    colorScheme {
      label
    }
  }
`);

export const PAGE_OVERVIEW_CARD_FRAGMENT = gql(`
  fragment pageOverviewCard on overview_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
    colorScheme {
      label
    }
    programCategory {
      ...programCategory
    }
  }
`);

export const PAGE_EXPANDABLE_CARD_FRAGMENT = gql(`
  fragment pageExpandableCard on expandable_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
    colorScheme {
      label
    }
  }
`);

export const SHORTLIST_CARD_FRAGMENT = gql(`
  fragment shortlistCard on index_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
  }
`);

export const SEARCH_CARD_FRAGMENT = gql(`
  fragment searchCard on index_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
  }
`);

export const NOTFOUND_CARD_FRAGMENT = gql(`
  fragment notFoundCard on notFound_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
  }
`);

export const FAQ_CARD_FRAGMENT = gql(`
  fragment faqCard on faq_Entry {
    ...entryBase
    heading
    content: htmlContentDefault
    faqCategory {
      ...faqCategory
    }
  }
`);

export const FAQ_INDEX_CARD_FRAGMENT = gql(`
  fragment faqIndexCard on faqIndex_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
  }
`);

//  -------------------------------------------------------------------------------------------------
//  ---- Event Cards ----

export const EVENT_INDEX_CARD_FRAGMENT = gql(`
  fragment eventIndexCard on index_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...image
    }
  }
`);

export const EVENT_CARD_FRAGMENT = gql(`
  fragment eventCard on event_Entry {
    ...entryBase
    status
    entryTitle
    entrySubTitle
    entrySummary
    entryImage {
      ...image
    }
    videoUrl
    genreCategory {
      ...genreCategory
    }
    eventTime {
      ...eventTimeCard
    }
    eventId
    venueCategory {
      ...categories
    }
    programCategory {
      ...programCategory
    }
  }
`);

export const EVENT_PRICE_CARD_FRAGMENT = gql(`
  fragment eventPriceCard on eventPrice_Entry {
    ...entryBase
    ownerId
    title
    minPrice
    maxPrice
  }
`);

export const EVENT_TIME_CARD_FRAGMENT = gql(`
  fragment eventTimeCard on eventTime_Entry {
    ...entryBase
    ownerId
    date
    eventTimeId
  }
`);

export const EVENT_INFO_CARD_FRAGMENT = gql(`
  fragment eventInfoCard on eventInfo_Entry {
    ...entryBase
    heading
    content: htmlContentSimple
  }
`);

//  -------------------------------------------------------------------------------------------------
//  ---- Cards ----

export const ENTRY_CARDS_FRAGMENT = gql(`
  fragment entryCards on EntryInterface {
    ...articleIndexCard
    ...articleCard
    ...homeCard  
    ...venueCard
    ...pageStandardCard
    ...pageOverviewCard
    ...pageExpandableCard
    ...shortlistCard
    ...searchCard
    ...notFoundCard
    ...eventIndexCard
    ...eventCard

    ...faqCard
    # ...eventPriceCard
    # ...eventTimeCard
    # ...eventInfoCard
  }
`);
