import BlockHeader from '@/components/BlockHeader';
import DownloadButton from '@/components/DownloadButton';
import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';
import * as styles from './FileDownloadBlock.css';

export const FILE_DOWNLOAD_BLOCK_FRAGMENT = gql(`
  fragment fileDownloadBlock on blocks_fileDownload_BlockType {
    heading
    fileMultiple {
      ...document
    }
  }
`);

export const FileDownloadBlock = createBlock(FILE_DOWNLOAD_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading, fileMultiple } = data;

  return (
    <BlockContainer
      {...{
        colorSet: 'white',
        paper: true,
        meta,
        marginY: true,
        disableTransition: true,
        customAnchor: heading,
      }}>
      <BlockHeader
        heading={heading}
        HeadingProps={{ cx: { mB: 'xl' }, autoMargin: false }}
        maxWidth="none"
      />
      <Flex direction="row" wrap spacing="gutter">
        {fileMultiple?.map((document, i) => {
          return (
            <Box key={i} className={styles.fileWrapper}>
              <DownloadButton data={document} />
            </Box>
          );
        })}
      </Flex>
    </BlockContainer>
  );
});

export default FileDownloadBlock;
