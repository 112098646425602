'use client';

import UnstyledAccordion from '@/components/UnstyledAccordion';
import { UnstyledAccordionItemProps } from '../UnstyledAccordion/UnstyledAccordion.Item';
import * as styles from './FaqCard.css';

const FaqCardAccordionItem = ({ index, ...props }: UnstyledAccordionItemProps) => {
  return (
    <UnstyledAccordion.Item
      {...{
        index,
        className: styles.accordionItem,
        colorSet: 'primaryTintLighter',
        paper: true,
        ...props,
      }}
    />
  );
};

export default FaqCardAccordionItem;
