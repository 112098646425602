import { withFragment } from '@liquorice/gql-utils';
import { gql } from '__generated__';
import Box from '../ui/Box';

export const TABLE_FRAGMENT = gql(`
    fragment table on table_Entry {
        id
        row {
            ...row
        }
    }
`);

const Table = withFragment(TABLE_FRAGMENT, (data) => {
  return (
    <Box>
      {/* {data.row.map((row) => (
      <Row key={row.id} data={row} />
    ))} */}
      <p>TABLE!</p>
    </Box>
  );
});

export default Table;
