import { useColorScheme } from '@/lib/utils/colorScheme';
import Card, { CardProps } from '../Card';
import Box from '../ui/Box';

export default function BasicCard(props: CardProps) {
  const { oppositeScheme } = useColorScheme(
    props?.colorSet === 'default' ? 'primary' : props.colorSet
  );

  return (
    <Card fullHeight elevation={false} padding="sm" {...props}>
      <Box>
        <Card.Image ratio="landscape" />
      </Box>
      <Card.Body>
        <Card.Title variant="h5" />
        <Card.Description />
      </Card.Body>
      {!!props.item.href && (
        <Card.Foot>
          <Card.Cta color={oppositeScheme} variant="outlined" fullWidth />
        </Card.Foot>
      )}
    </Card>
  );
}
