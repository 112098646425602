import { Sprinkles } from '@/theme/sprinkles.css';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, isTypedElement, toString } from '@liquorice/utils';
import { FragmentType, gql } from '__generated__';
import NextImage, { ImageProps } from 'next/image';
import Shim, { ShimProps } from '../Shim';
import Box from '../ui/Box';
import Txt from '../ui/Txt';
import * as styles from './Image.css';
import CardMaskImage from './Image.Mask';

export const IMAGE_FRAGMENT = gql(`
  fragment image on AssetInterface {
    ...on assetsVolume_Asset {
      __typename
      id
      mimeType
      title
      uid
      url
      height
      width
      alt
    }
  }
`);

export type ImageOwnProps = OverrideProps<
  Partial<ImageProps>,
  {
    ShimProps?: ShimProps;
    ratio?: Sprinkles['ratio'];
    rounded?: boolean;
    captions?: boolean;
    // transform?: ResponsiveImageTransform;
    mask?: boolean;
  } & styles.ImageVariants
>;

export type ImageAssetProps = ImageOwnProps & {
  data?: MaybeImage;
};

export type MaybeImage = MaybeArrayOf<FragmentType<typeof IMAGE_FRAGMENT>>;

const Image = withFragmentArray(IMAGE_FRAGMENT, (images, props: ImageOwnProps) => {
  const {
    ratio,
    ShimProps: maybeShimProps,
    fixedRatio: maybeFixedRatio,
    contain,
    rounded,
    round,
    mask = false,
    captions,
    ...rest
  } = props;

  const image = firstNonNullable(images);

  if (!isTypedElement(image, 'assetsVolume_Asset')) return null;

  if (!image.url) return null;

  if (mask) return <CardMaskImage src={image.url} title={toString(image.uid)} {...rest} />;

  /**
   * Create the Shim
   */
  const ShimProps: ShimProps<TagName> | undefined =
    maybeShimProps || ratio || rounded || round
      ? (mergePropsClassName(
          { ratio, round, rounded, ...maybeShimProps },
          styles.shim
        ) as ShimProps<TagName>)
      : undefined;

  const fixedRatio = maybeFixedRatio ?? !!ShimProps;

  const img = (
    <NextImage
      {...{
        src: image.url,
        alt: image.alt ?? '',
        height: image.height ?? 100,
        width: image.width ?? 100,
        ...mergePropsClassName(
          rest,
          styles.root({
            fixedRatio,
            contain,
          })
        ),
      }}
    />
  );

  if (ShimProps) return <Shim {...ShimProps}>{img}</Shim>;

  if (captions)
    return (
      <Box>
        {img}
        {image.alt && (
          <Txt color="black" as="figcaption" cx={{ mT: 'md' }}>
            {image.alt}
          </Txt>
        )}
      </Box>
    );

  return img;
});

export default Image;
