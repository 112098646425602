'use client';

import UnstyledAccordion from '@/components/UnstyledAccordion';
import { PropsWithChildren } from 'react';

const FaqCardAccordion = ({ children }: PropsWithChildren) => {
  return (
    <UnstyledAccordion BoxProps={{ cx: { display: 'flex', rowGap: 'sm', direction: 'column' } }}>
      {children}
    </UnstyledAccordion>
  );
};

export default FaqCardAccordion;
