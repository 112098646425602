import BasicCard from '@/components/BasicCard';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField, useColorScheme } from '@/lib/parsers/common';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { toStringOrNull } from '@liquorice/utils';
import { EXTERNAL_CARD_BLOCK_FRAGMENT } from './useExternalCard';

export const EntryExternalCardBlock = createBlock(EXTERNAL_CARD_BLOCK_FRAGMENT, ({ data }) => {
  const { heading, content, linkField, imageSingle } = data;
  const link = parseLinkField(linkField);
  const newWindow = link?.target === '_blank';
  const { colorScheme } = useColorScheme(data.colorScheme);
  const title = toStringOrNull(heading);
  const description = cleanHtml(content);

  return (
    <BasicCard
      colorSet={colorScheme}
      newWindow={newWindow}
      item={{
        title,
        description,
        image: imageSingle,
        href: link?.href,
        customText: link?.children,
      }}
    />
  );
});

export default EntryExternalCardBlock;
