'use client';

import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { ARTICLE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import Card from '../Card';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import createCard from './createCard';

const ArticleCard = createCard(ARTICLE_CARD_FRAGMENT, ({ data, overrides, ...props }) => {
  const { entryImage, entryTitle, entrySummary: description, ...base } = data;
  const { uri, postDate } = getFragmentData(ENTRY_BASE_FRAGMENT, base);
  const { heading, image: maybeImage } = overrides ?? {};

  const image = !!firstNonNullable(maybeImage) ? maybeImage : entryImage;
  const href = parseUri(uri);

  return (
    <Card
      disableLinkWrap
      colorSet="white"
      paper
      fullHeight
      elevation={false}
      {...props}
      item={{
        href,
        image,
        title: heading ?? entryTitle,
        meta: postDate,
        description,
        // customText,
      }}>
      <Box cx={{ mB: '2xs' }}>
        <Card.Image ratio="landscape" enableLinkWrap />
      </Box>
      <Card.Body cx={{ mB: 'lg' }}>
        <Card.Title variant="medium" weight="bold" />
        <Card.Description variant="small" color="neutralDark" enableTruncate />
      </Card.Body>
      <Card.Foot cx={{ display: 'flex', justifyContent: 'between' }}>
        <Card.Meta cx={{ fontFamily: 'mono', fontSize: 'tiny' }} uppercase />
        {href && (
          <Card.Cta
            variant="text"
            weight="bold"
            href={href}
            EndIconProps={{ name: 'chevronRight', cx: { fontSize: 'h5' } }}
            cx={{ fontSize: 'medium', mB: 'xs' }}
          />
        )}
      </Card.Foot>
      <Divider direction="horizontal" cx={{ color: 'primary' }} />
    </Card>
  );
});

export default ArticleCard;
