import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks';
import * as richTextStyles from '@/components/Blocks/BlockComponents/RichTextBlock/RichTextBlock.css';
import CtaButton from '@/components/CtaButton';
import Image from '@/components/Image';
import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkFieldMultiple } from '@/lib/parsers/common/parseLinkFieldMultiple';
import { gql } from '__generated__';

export const IMAGE_AND_TEXT_BLOCK_FRAGMENT = gql(`
  fragment imageAndTextBlock on blocks_imageAndText_BlockType {
    heading
    content: htmlContentDefault
    linkFieldMultiple {
      ...linkFieldMultiple
    }
    flipAlignment
    imageSingle {
      ...image
    }
    lightswitch
  }
`);

const ImageAndTextBlock = createBlock(IMAGE_AND_TEXT_BLOCK_FRAGMENT, ({ data, meta }) => {
  const {
    heading,
    content,
    linkFieldMultiple,
    flipAlignment,
    imageSingle,
    lightswitch: quarterImage,
  } = data;

  const links = parseLinkFieldMultiple(linkFieldMultiple);

  return (
    <BlockContainer {...{ meta, disableTransition: true, marginY: true, customAnchor: heading }}>
      <Grid
        cx={{
          alignItems: 'center',
          justifyContent: 'between',
          flexDirection: { md: flipAlignment ? 'row-reverse' : 'row' },
        }}>
        <Grid.Col md={quarterImage ? 9 : 6}>
          <BlockHeader
            cx={{ p: '2xs' }}
            maxWidth="none"
            heading={heading}
            HeadingProps={{ variant: 'h5', color: 'primary', cx: { mB: 'md' } }}
            content={content}
            ContentProps={{ className: richTextStyles.withRichText }}>
            <Flex cx={{ mT: 'md' }} wrap spacing="gutter">
              {links?.map((link, i) => {
                return (
                  <CtaButton
                    key={i}
                    variant="filled"
                    size="large"
                    rounded="small"
                    endIcon={undefined}
                    {...link}
                  />
                );
              })}
            </Flex>
          </BlockHeader>
        </Grid.Col>
        <Grid.Col md={quarterImage ? 3 : 6}>
          <Box cx={{ p: '2xs' }}>
            <Image alt="" data={imageSingle} />
          </Box>
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default ImageAndTextBlock;
