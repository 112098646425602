import { Color } from '@/theme/partials/palette.css';
import { findOppositeColorScheme } from '../parsers/common';

export const useColorScheme = (
  maybeColorScheme?: Color
): { colorScheme: Color; oppositeScheme: Color } => {
  const defaultScheme = 'primary' as Color;

  return {
    colorScheme: maybeColorScheme ?? defaultScheme,
    oppositeScheme: findOppositeColorScheme(maybeColorScheme ?? defaultScheme),
  };
};
