'use client';

import {
  CATEGORIES_FRAGMENT,
  EVENT_LABEL_CATEGORY_FRAGMENT,
  PROGRAM_CATEGORY_FRAGMENT,
} from '@/gql/fragments/categories.gql';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useEventFAQ } from '@/layouts/EventLayout/EventLayout.Body';
import { useColorScheme } from '@/lib/parsers/common';
import useShortlistStore from '@/lib/store/shortlist';
import { createFragmentParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, firstNonNullable } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { EventEntryFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Breadcrumbs from '../Breadcrumbs';
import IconButton from '../IconButton';
import PageTicket from '../PageTicket';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Container from '../ui/Container';
import Grid from '../ui/Grid';
import { PageLabel } from './PageButton';
import * as styles from './PageHeader.css';
import PageHeaderRoot from './PageHeaderRoot';
import PageMedia from './PageMedia';
import PageSubTitle from './PageSubTitle';
import PageTitle from './PageTitle';

const PageHeaderEvent = (props: EventEntryFragment) => {
  const t = useTranslations('entryIndex');
  const card = getFragmentData(EVENT_CARD_FRAGMENT, props);
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, card);
  const { entryImage, entrySubTitle, videoUrl } = card;
  const { programCategory, eventLabelCategory } = props;
  const disabled = base.status === 'expired';

  const { value: eventFaq, off: disableEventFaq, on: enableEventFaq } = useEventFAQ();
  const events = useShortlistStore((s) => s.events);
  const toggleEvent = useShortlistStore((s) => s.toggleEvent);

  const getBaseData = createFragmentParser(ENTRY_BASE_FRAGMENT, (data) => data);

  const isShortlisted = events?.some((e) => getBaseData(e)?.id === base.id);

  const getCategory = createFragmentParser(CATEGORIES_FRAGMENT, (data) => data);
  const parseProgram = createFragmentParser(PROGRAM_CATEGORY_FRAGMENT, (data) => data);
  const category = getCategory(firstNonNullable(programCategory));
  const program =
    category?.__typename === 'programCategory_Category' ? parseProgram(category) : undefined;

  const eventLabel = firstNonNullable(
    getFragmentData(EVENT_LABEL_CATEGORY_FRAGMENT, excludeEmptyTypedElements(eventLabelCategory))
  );

  const { colorScheme: programColorScheme } = program ?? {};
  const { colorScheme, oppositeScheme } = useColorScheme(programColorScheme, 'secondary');

  const defaultScheme = colorScheme || 'secondary';
  const colorSet = disabled ? 'notifyRed' : defaultScheme;

  return (
    <PageHeaderRoot
      variant="event"
      colorSet={colorSet}
      paper
      GridProps={{ cx: { rowGap: 'md', pT: 'xl', pB: 'xl' } }}
      GridColProps={{ md: 8 }}
      beforeContainer={
        <Box className={styles.eventHero}>
          <Box cx={{ width: 'full', height: 'full' }}>
            <PageMedia
              VideoProps={{ videoUrl }}
              ImageProps={{
                priority: true,
                fixedRatio: true,
                className: styles.eventHeroImage,
                data: entryImage,
              }}
            />
          </Box>
          <Container cx={{ position: 'relative' }}>
            {(eventLabel?.title || disabled) && (
              <PageLabel cx={{ bgcolor: colorSet }}>
                {disabled ? t('pastEvent') : eventLabel?.title}
              </PageLabel>
            )}
            <IconButton
              elevation="button"
              color="white"
              variant="text"
              icon={isShortlisted ? 'heart' : 'heartOutlined'}
              cx={{ mY: 'sm', mX: 'md' }}
              className={styles.eventShortlistButton}
              onClick={() => toggleEvent(card)}
            />
          </Container>
        </Box>
      }
      afterCol={
        <Grid.Col md={4}>
          <PageTicket
            parentEvent={card}
            TicketBtnProps={{ color: disabled ? 'error' : oppositeScheme }}
          />
        </Grid.Col>
      }
      afterContainer={
        <Container>
          <Grid>
            <Grid.Col sm={8} cx={{ display: 'flex', direction: 'row', columnGap: '6xs' }}>
              <Btn
                color="white"
                disableHover
                className={styles.eventFaqButton({ border: !eventFaq })}
                onClick={() => disableEventFaq()}>
                Description
              </Btn>
              <Btn
                color="white"
                disableHover
                className={styles.eventFaqButton({ border: eventFaq })}
                onClick={() => enableEventFaq()}>
                FAQs
              </Btn>
            </Grid.Col>
          </Grid>
        </Container>
      }>
      <Breadcrumbs
        data={base}
        cx={{ mB: 'xs' }}
        CtaButtonProps={{ color: disabled ? 'white' : oppositeScheme }}
        TxtProps={{ weight: 'bold' }}
      />
      <PageTitle bodyFont={true} weight="bold" variant="h2">
        {card.entryTitle}
      </PageTitle>
      <PageSubTitle useGridCol={false}>{entrySubTitle}</PageSubTitle>
    </PageHeaderRoot>
  );
};

export default PageHeaderEvent;
