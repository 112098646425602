import { BlockContainer } from '@/components/Blocks';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkFieldMultiple } from '@/lib/parsers/common/parseLinkFieldMultiple';
import { gql } from '__generated__';

export const BUTTON_BLOCK = gql(`
  fragment buttonBlock on blocks_button_BlockType {
    linkFieldMultiple {
      ...linkFieldMultiple
    }
  }
`);

const ButtonBlock = createBlock(BUTTON_BLOCK, ({ data, meta }) => {
  const { linkFieldMultiple } = data;

  const links = parseLinkFieldMultiple(linkFieldMultiple);

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      <Grid cx={{ justifyContent: 'center', rowGap: { xs: 'xl', lg: '3xl' } }}>
        {links?.map((link, i) => {
          return (
            <Grid.Col key={i} md={4}>
              <Btn
                fullWidth
                weight="bold"
                variant="filled"
                color="primary"
                size="large"
                rounded="small"
                {...link}
              />
            </Grid.Col>
          );
        })}
      </Grid>
    </BlockContainer>
  );
});

export default ButtonBlock;
