import { DOCUMENT_FRAGMENT } from '@/gql/fragments/document.gql';
import { withFragment } from '@liquorice/gql-utils';
import { humanFileSize, makeNonNullableArray } from '@liquorice/utils';
import IconButton from '../IconButton';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './DownloadButton.css';

const DownloadButton = withFragment(DOCUMENT_FRAGMENT, (data, props: { title?: string }) => {
  const { url: href, extension, size } = data ?? {};

  const title = props.title ?? data?.title;

  const ext = extension ? extension.toUpperCase() : null;
  const sizeHuman = humanFileSize(size);
  const meta = makeNonNullableArray([ext, size]);

  const metaText = `(${meta.join(', ')})`.toUpperCase();
  const ariaLabel = `${title} ${metaText}`;

  return !href ? (
    <></>
  ) : (
    <Flex
      colorSet="white"
      paper
      direction="row"
      alignItems="center"
      columnGap="sm"
      bordered
      rounded
      as="a"
      download={'download'}
      aria-label={ariaLabel}
      href={href}
      target="_blank"
      className={styles.root}
      {...props}>
      <IconButton
        variant="filled"
        square
        rounded="large"
        size="normal"
        disableHover
        color="primaryTintLighter"
        icon="download"
        IconProps={{ className: styles.icon }}
      />
      <Box>
        <Txt as="span" variant="small" weight="bold">
          {title}
        </Txt>
        <Flex direction="row" justifyContent="between">
          <Txt as="span" variant="small">
            {ext}
          </Txt>
          <Txt as="span" variant="small">
            {sizeHuman}
          </Txt>
        </Flex>
      </Box>
    </Flex>
  );
});

export default DownloadButton;
