import { useEntryCards } from '@/components/EntryCards/useEntryCards';
import OverviewCard from '@/components/OverviewCard';
import { PROGRAM_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField } from '@/lib/parsers/common';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { createFragmentParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, firstNonNullable, maybeGet, parseUri } from '@liquorice/utils';
import { getFragmentData, gql } from '__generated__';

export const OVERVIEW_CARD_BLOCK_FRAGMENT = gql(`
  fragment overviewCardBlock on entryCard_Entry {
    entrySingle {
      ...entryCards
    }
    
    # Override props
    imageSingle {
      ...image
    }
    heading
    content: htmlContentSimple
    linkField {
      ...linkField
    }
  }
`);

export const OverviewCardBlock = createBlock(OVERVIEW_CARD_BLOCK_FRAGMENT, ({ data }) => {
  const entry = firstNonNullable(data.entrySingle);
  const parseEntryCards = createFragmentParser(ENTRY_CARDS_FRAGMENT, (data) => data);
  const parseBase = createFragmentParser(ENTRY_BASE_FRAGMENT, (data) => data);
  const { heading, content, linkField, imageSingle } = data;

  const card = useEntryCards(parseEntryCards(entry));

  const { fragment } = card ?? {};

  const base = parseBase(fragment);

  const maybeLink = parseLinkField(linkField);
  const maybeTitle = heading;
  const maybeDescription = cleanHtml(content);

  const isFAQ = fragment?.__typename === 'faq_Entry';

  const title = isFAQ ? fragment.heading : maybeTitle ?? fragment?.entryTitle;
  const description = isFAQ ? fragment.content : maybeDescription ?? fragment?.entrySummary;
  const image = isFAQ ? null : imageSingle ? fragment?.entryImage : null;

  const href = maybeLink?.href ? maybeLink?.href : parseUri(base?.uri);
  const customText = maybeLink?.text;

  const maybeColorScheme = maybeGet(fragment, 'colorScheme');
  const maybeProgram = maybeGet(fragment, 'programCategory');
  const programCategory = getFragmentData(
    PROGRAM_CATEGORY_FRAGMENT,
    firstNonNullable(excludeEmptyTypedElements(maybeProgram))
  );

  return (
    <OverviewCard
      {...{
        item: {
          title,
          description,
          href,
          image,
          customText,
        },
        colorScheme: programCategory?.colorScheme ?? maybeColorScheme,
      }}
    />
  );
});

export default OverviewCardBlock;
