'use client';

import { PROGRAM_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { PAGE_OVERVIEW_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useColorScheme } from '@/lib/parsers/common';
import { useBreakpointMin } from '@/theme';
import { withFragment } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, firstNonNullable } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import Breadcrumbs from '../Breadcrumbs';
import ColorMask from '../Image/Color.Mask';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import PageHeaderRoot from './PageHeaderRoot';
import PageImage from './PageImage';
import PageSummary from './PageSummary';
import PageTitle from './PageTitle';

const PageHeaderOverview = withFragment(PAGE_OVERVIEW_CARD_FRAGMENT, (data) => {
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, data);
  const programCategory = getFragmentData(
    PROGRAM_CATEGORY_FRAGMENT,
    firstNonNullable(excludeEmptyTypedElements(data?.programCategory))
  );
  const sm = useBreakpointMin('sm');
  const { colorScheme, oppositeScheme } = useColorScheme(
    programCategory?.colorScheme ?? data?.colorScheme
  );

  if (!data) return null;

  return (
    <PageHeaderRoot
      variant="overview"
      colorSet={colorScheme}
      paper
      GridColProps={{ md: 8 }}
      afterCol={
        sm && (
          <Grid.Col offsetMd={1} sm={5} md={3}>
            {!!firstNonNullable(data.entryImage) ? (
              <PageImage mask={true} data={data.entryImage} />
            ) : (
              <ColorMask color={colorScheme} />
            )}
          </Grid.Col>
        )
      }>
      <Flex spacing="xs" cx={{ mY: '5xl' }}>
        <Breadcrumbs data={base} CtaButtonProps={{ color: oppositeScheme }} />
        <PageTitle monoFont uppercase cx={{ mB: 'none' }}>
          {data.entryTitle}
        </PageTitle>
        <PageSummary weight="bold" useGridCol={false}>
          {data.entrySummary}
        </PageSummary>
      </Flex>
    </PageHeaderRoot>
  );
});

export default PageHeaderOverview;
