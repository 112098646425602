import * as richTextStyles from '@/components/Blocks/BlockComponents/RichTextBlock/RichTextBlock.css';
import UnstyledAccordion from '@/components/UnstyledAccordion';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { vars } from '@/theme/vars.css';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const ACCORDION_ITEM_BLOCK_FRAGMENT = gql(`
  fragment accordionItemBlock on accordionItem_Entry {
    heading
    content: htmlContentSimple
  }
`);

export const ACCORDION_BLOCK_FRAGMENT = gql(`
  fragment accordionBlock on blocks_accordion_BlockType {
    accordionItem {
      ...accordionItemBlock
    }
  }
`);

export const AccordionBlock = createBlock(ACCORDION_BLOCK_FRAGMENT, ({ data, meta }) => {
  const accordion = excludeEmptyTypedElements(data.accordionItem);
  const parseItems = createFragmentArrayParser(ACCORDION_ITEM_BLOCK_FRAGMENT, (data) => data);

  const items = parseItems(accordion);

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      <UnstyledAccordion
        items={
          items?.map((child, index: number) => {
            return {
              index,
              style: { borderColor: vars.palette.colors.primaryTintLight },
              HandleProps: { cx: { pY: 'sm' } },
              LabelProps: {
                children: child?.heading,
                underline: 'none',
                weight: 'bold',
                color: 'primary',
                variant: 'text',
              },
              CaretProps: {
                index,
                openIconProps: {},
              },
              BodyProps: {
                index,
                children: (
                  <Txt html className={richTextStyles.withRichText}>
                    {child?.content}
                  </Txt>
                ),
              },
            };
          }) ?? []
        }
      />
    </BlockContainer>
  );
});

export default AccordionBlock;
