import BlockHeader from '@/components/BlockHeader';
import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';
import { EntryExternalCardBlock } from '../ExternalCardBlock/ExternalCardBlock.Entry';
import { SimpleCardBlock } from '../SimpleCardBlock';

export const ENTRY_CARDS_BLOCK_FRAGMENT = gql(`
  fragment entryCardsBlock on blocks_entryCards_BlockType {
    heading
    simpleCardMultiple {
      ...simpleCardBlock
      ...externalCardBlock
    }
  }
`);

export const EntryCardsBlock = createBlock(ENTRY_CARDS_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading } = data;
  const items = excludeEmptyTypedElements(data.simpleCardMultiple);

  return (
    <BlockContainer {...{ meta, marginY: true, disableTransition: true, customAnchor: heading }}>
      <BlockHeader
        maxWidth="none"
        alignItems="center"
        heading={heading}
        HeadingProps={{ cx: { mB: 'xl' }, autoMargin: false, color: 'primary' }}
      />
      <SimpleGrid
        md={6}
        lg={4}
        rowGutter={false}
        cx={{ justifyContent: 'center', rowGap: { xs: 'xl', lg: '3xl' } }}>
        {items?.map((item, index: number) =>
          item?.__typename === 'externalCard_Entry' ? (
            <EntryExternalCardBlock key={index} data={item} />
          ) : (
            <SimpleCardBlock key={index} data={item} />
          )
        )}
      </SimpleGrid>
    </BlockContainer>
  );
});

export default EntryCardsBlock;
