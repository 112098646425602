'use client';

import Box from '@/components/ui/Box';

import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { withFragment } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import Card from '../Card';
import FaqCard from '../FaqCard';
import * as styles from './EntryCard.css';
import { useEntryCards } from './useEntryCards';

const SearchCard = withFragment(ENTRY_CARDS_FRAGMENT, (data) => {
  const { fragment, typeLabel } = useEntryCards(data) ?? {};
  const isFAQ = fragment?.__typename === 'faq_Entry';
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, fragment);
  const title = isFAQ ? fragment.heading : fragment?.entryTitle;
  const description = isFAQ ? fragment.content : fragment?.entrySummary;
  const href = parseUri(base?.uri);

  const image = isFAQ ? null : fragment?.entryImage;

  if (isFAQ) return <FaqCard {...fragment} />;

  return (
    <Card
      elevation={false}
      colorSet="white"
      paper
      item={{
        href,
        title,
        image,
        description,
        meta: typeLabel,
      }}>
      {base?.sectionHandle !== 'page' && (
        <Box cx={{ position: 'relative' }}>
          {!!firstNonNullable(image) && (
            <Card.Meta className={styles.tag} color="primary" monoFont uppercase variant="tiny" />
          )}
          <Card.Image ratio="landscape" />
        </Box>
      )}
      <Card.Body cx={{ rowGap: 'xs', mY: 'xs' }}>
        <Card.Title variant="h5" as="span" />
        <Card.Description variant="small" />
        <Card.Cta
          variant="text"
          size="small"
          EndIconProps={{ name: 'chevronRight' }}
          cx={{ fontSize: 'medium' }}
        />
      </Card.Body>
    </Card>
  );
});

export default SearchCard;
