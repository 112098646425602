import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks';
import * as richTextStyles from '@/components/Blocks/BlockComponents/RichTextBlock/RichTextBlock.css';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';

export const BREAKOUT_BOX_BLOCK_FRAGMENT = gql(`
  fragment breakoutBoxBlock on blocks_breakoutBox_BlockType {
    heading
    content: htmlContentDefault
  }
`);

const BreakoutBoxBlock = createBlock(BREAKOUT_BOX_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading, content } = data;

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
        customAnchor: heading,
      }}>
      <BlockHeader
        maxWidth="none"
        heading={heading}
        HeadingProps={{ variant: 'h3', color: 'primary', cx: { mB: 'md' } }}
        content={content}
        ContentProps={{ className: richTextStyles.withRichText }}
      />
    </BlockContainer>
  );
});

export default BreakoutBoxBlock;
