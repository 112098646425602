import BlockHeader from '@/components/BlockHeader';
import Image from '@/components/Image';
import SkeletonCards from '@/components/SkeletonCards';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { GENRE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { useCategory } from '@/lib/getters/useCategory';
import { createBlock } from '@/lib/parsers/blocks';
import { useAppContext } from '@/lib/store/hooks';
import { createFragmentParser } from '@liquorice/gql-utils';
import { parseUri } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';
import * as styles from './CategoryTypeBlock.css';

export const EVENT_TYPE_BLOCK_FRAGMENT = gql(`
  fragment categoryTypeBlock on blocks_categoryType_BlockType {
    heading
  }
`);

export const CategoryTypeBlock = createBlock(EVENT_TYPE_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading } = data;
  const category = useCategory({ group: 'genreCategory' });
  const loading = category?.loading;

  const { commonLinks } = useAppContext() ?? {};
  const eventIndexUri = parseUri(commonLinks?.eventIndex?.uri);

  const categoryParser = createFragmentParser(GENRE_CATEGORY_FRAGMENT, (data) => data);
  const items = category?.result.map((item) => {
    if (item.__typename === 'genreCategory_Category') return categoryParser(item);
  });

  return (
    <BlockContainer
      {...{
        colorSet: 'primaryTintLighter',
        paper: true,
        meta,
        marginY: 'none',
        paddingY: true,
        disableTransition: true,
        overflow: true,
        customAnchor: heading,
      }}>
      <BlockHeader
        maxWidth="none"
        heading={heading}
        HeadingProps={{ variant: 'h3', cx: { mB: 'xl' }, autoMargin: false }}
        cx={{ alignItems: 'center' }}
      />
      {loading ? (
        <SkeletonCards
          amount={12}
          xs={4}
          md={2}
          SkeletonItems={{
            bgcolor: 'white',
            items: [
              { variant: 'rounded', height: 115 },
              { variant: 'rounded', height: 25 },
            ],
          }}
        />
      ) : (
        <Grid cx={{ justifyContent: 'center', alignItems: 'start', rowGap: 'md' }}>
          {items?.map((item, i) => {
            return (
              item && (
                <Grid.Col
                  key={i}
                  xs={4}
                  md={2}
                  cx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end',
                  }}>
                  <Btn
                    key={item.id}
                    href={`${eventIndexUri}?genreCategory=${item.id}`}
                    underline="none"
                    color="primaryTintLighter"
                    variant="text"
                    cx={{
                      display: 'flex',
                      direction: 'column',
                      alignItems: 'center',
                      rowGap: '2xs',
                    }}>
                    <Image alt="" data={item.imageSingle} className={styles.image} />
                    <Txt
                      color="primary"
                      variant="tiny"
                      monoFont
                      uppercase
                      weight="regular"
                      cx={{ whiteSpace: 'normal', textAlign: 'center' }}>
                      {item.title}
                    </Txt>
                  </Btn>
                </Grid.Col>
              )
            );
          })}
        </Grid>
      )}
    </BlockContainer>
  );
});

export default CategoryTypeBlock;
