import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63V3WrCMBTA8XufIpCbCTqSpmnSeLs9SJo0zvrRrVP3xd59xMI4FTkc2Mjd3%2FjjNFHqhr4%2Fsq8ZY8tl0vuu3wnHuKiEsnEFqrxZC8e48sqEAKtyjFtjdUqwlo7xUIVyWrVjPOl0tbdyjLdRCCthNXmvCW00sNpck01ewVpfaivayV6fa0wqaViby95UJw9ryFV5oUpYo2M8xqapJ%2FO2jvFHqQv1AGvKZya01JOnWDvGjc4L1ifHuA95wbpxjJc2L1i7cV6YtvnACiGEWM2%2BZ%2FdjPEzudefY2Q93v9c8h9%2FfTz80c8D0GCMxpoPMM8YojJGQecGYgjzNgDEleZpXjNFk5ogxFfmhThhjyNOcMaYmM28YY8nM%2B%2F8wHxjTkJlPjAlkRgrMiXRHYk5L%2FuXIAnMS3VGYs6Y7JeY8Yc5m4mjM2dDnqTCno9%2BXwZwtfR77x3maPo7%2FiNDv%2BuHqWBb5vTW%2BIBoftuuhPx3i8sbObnF5E2XxB2qfoqNJCAAA%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62WzY6bMBSF93kKLxM1WP43ZlazmGVXfYCKgJO4JZABmvmp%2Bu5VCIQxXEujSbbX3I97zvFFJHVVtejvAqEo%2BkmdZhtHSYJOab2Mol37eLC1y9Kn19aWuc1X6%2BnJjzYt87Q%2BnzzWLi3WqEnLJmps7bYPHpXOqJ%2FvZTf08lnv96qsblEiEpQV6eG4ZJgyWdvDGmVpkS0jikVMVFdC35DGkrLTy2qNRP%2FcyufIgUOxmnCINgNGYsVFh%2BEwRo2YjxSCpeaG6x5DsSBxhxneNsHoEaM%2FYChmxnA6TEOw4abDsDkiDiForK62UEz1RQ%2FDBJzEjBjqK1JaQKNQfJE54aQhYzSsiGIAshkgHHuSlNE0HszlWEvVRw0wsnDQ8uzugFHnyPqoQUV5ctXqH9h7SN0GfY%2BNUSNEqAECpbcbKMS7BARLJgAIwTGkdB%2BiBG4jnSPcdU09xxWR0IrO%2B3%2BNdviZMSKA5ZwDfgf9FCKGNxNworhHtIe7RFuOocR%2BKoIFFhP8YlVBjhLgNHPEEXYlotgQAW7UnPF8ZXjXVARshZTUtytp7rIv7UhRfsAcDLh%2F2YTyJ0GCEL92SpCc1l4SpKa11wTpae0N6H1P0KRy%2FuEgD4t%2Fi02Vv3X%2FItuqbKPGvduv2lu40kZ763b79uvrU9i2tXXUHNPMlbvLjP8BBCJUtjAJAAA%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMjRweDsKICAtLV8xYWNlYjRxMzogMjRweDsKfQ%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuMTI1cmVtOwogIC0ta2RtdnFpNzogMC4yNXJlbTsKICAtLWtkbXZxaTg6IDAuMzc1cmVtOwogIC0ta2RtdnFpOTogMC41cmVtOwogIC0ta2RtdnFpYTogMC43NXJlbTsKICAtLWtkbXZxaWI6IDFyZW07CiAgLS1rZG12cWljOiAxLjI1cmVtOwogIC0ta2RtdnFpZDogMS41cmVtOwogIC0ta2RtdnFpZTogMnJlbTsKICAtLWtkbXZxaWY6IDIuMzc1cmVtOwogIC0ta2RtdnFpZzogY2xhbXAoMi4zNzVyZW0sIGNhbGMoMS4yNDgyNXJlbSArIDIuMzQ3dncpLCAzcmVtKTsKICAtLWtkbXZxaWg6IGNsYW1wKDNyZW0sIGNhbGMoMi4wOTg1NjI1cmVtICsgMS44Nzh2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlpOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS42OTcxODc1cmVtICsgMy43NTZ2dyksIDQuNXJlbSk7CiAgLS1rZG12cWlqOiBjbGFtcCgzLjVyZW0sIGNhbGMoMC43OTU3NXJlbSArIDUuNjM0dncpLCA1cmVtKTsKICAtLWtkbXZxaWs6IGNsYW1wKDVyZW0sIGNhbGMoMC40OTI5Mzc1cmVtICsgOS4zOTB2dyksIDcuNXJlbSk7Cn0%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U3W6jMBCF7%2FsU57KVQuThN8y%2BQN%2Bglyvz08RNgqkhLd3VvnsFSlrGtM1KIIbPZ84MxjY7a3v8vQGCwLqh3aeKEZBS6teMESPIJQoZJEnESCSJGakkyZQlUMqgSKKMId43jCRL22HOckaWbiTTjDwlyQoGUR5LWDIojr3sihFKUjM6ezDVnD16vW0Zap24%2BjiHuxFuMh8bBq0p9PETI1xY7Bkv2t0GwW8yWVgY6u7mwwcGeRnHsejCvBlrRotW7DK%2F9Ss%2Bi4rPyww3VlxYd77PQfj049cuJ%2BE09rmcsheGaofpTtsBblvoW7XC%2BVpTJLxfP9WkrsuHmfw%2F3N%2F4o4%2FwkuenhMnd6sM0%2FkZEifD9M%2F24Tmw4xZNOQvpCGI4skiwaWSxZPK1TyRJGocv91tlTUwWlPVi3wvlRWFfV7gKnXlDrrg5ME9hTL3zSr3x%2BTskYttWl6d%2BuCDeM3umme7TueEWaM3a12e76FY56uL%2BEprmEr6bqd9PgwzkyzTn62Vl7m54KhnekUMnwDhSqeFpXgtWMSI3s3zvXISe%2FewUAAA%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FBlocks%2FBlockContainer.css.ts.vanilla.css%22%2C%22source%22%3A%22LmR0c283MTAgewogIHBvc2l0aW9uOiByZWxhdGl2ZTsKICBkaXNwbGF5OiBibG9jazsKfQouZHRzbzcxMyB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5kdHNvNzE0IHsKICBtYXgtaGVpZ2h0OiAxNXJlbTsKfQouZHRzbzcxNSB7CiAgb3ZlcmZsb3c6IHZpc2libGU7Cn0KLmR0c283MTYgewogIG92ZXJmbG93OiBoaWRkZW47Cn0KLmR0c283MTcgewogIHBvc2l0aW9uOiBhYnNvbHV0ZTsKICB0b3A6IDA7CiAgbGVmdDogMDsKICB3aWR0aDogMTAwJTsKICBoZWlnaHQ6IDEwMCU7CiAgYmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KDE4MGRlZywgaHNsYSgwLCAwJSwgMTAwJSwgMCkgMCwgI2ZmZiAxMDAlKTsKICB6LWluZGV4OiB2YXIoLS1vcnhwazYzKTsKfQouZHRzbzcxOCB7CiAgLS1fMThtMzlzejExOiB2YXIoLS1rZG12cWlkKTsKICAtLV8xOG0zOXN6MTI6IHZhcigtLWtkbXZxaWMpOwogIHdpZHRoOiAxMDAlOwp9%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var button = 'dtso718';
export var container = _7a468({defaultClassName:'dtso713',variantClassNames:{expandable:{true:'dtso714'},overflow:{true:'dtso715',false:'dtso716'}},defaultVariants:{overflow:false},compoundVariants:[]});
export var root = _7a468({defaultClassName:'dtso710',variantClassNames:{last:{true:'dtso711'},first:{true:'dtso712'}},defaultVariants:{},compoundVariants:[]});
export var shim = 'dtso717';