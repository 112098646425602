'use client';

import { FaqCardFragment } from '__generated__/graphql';
import Box from '../ui/Box';
import Txt from '../ui/Txt';
import FaqCardAccordion from './FaqCard.Accordion';
import FaqCardAccordionItem from './FaqCard.AccordionItem';

const FaqCard = (props: FaqCardFragment) => {
  const index = 1; // Index is irrelevant as there will only be one item

  return (
    <FaqCardAccordion>
      <FaqCardAccordionItem
        key={props.heading}
        {...{
          index,
          LabelProps: {
            children: props?.heading,
            color: 'primary',
            variant: 'text',
          },
          BodyProps: {
            index,
            children: (
              <Box cx={{ pX: 'sm' }}>
                <Txt html>{props?.content}</Txt>
              </Box>
            ),
          },
        }}
      />
    </FaqCardAccordion>
  );
};

export default FaqCard;
