import { CardProps } from '@/components/Card';
import { useEntryCards } from '@/components/EntryCards/useEntryCards';
import { PROGRAM_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { parseColorScheme, parseLinkField } from '@/lib/parsers/common';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { createFragmentParser } from '@liquorice/gql-utils';
import {
  excludeEmptyTypedElements,
  firstNonNullable,
  maybeGet,
  parseUri,
  toString,
  toStringOrNull,
} from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { SimpleCardBlockFragment } from '__generated__/graphql';

/** Hook to apply card "overrides" and extra features */
export const useSimpleCard = ({
  entrySingle,
  heading,
  content,
  imageSingle,
  linkField,
}: SimpleCardBlockFragment): CardProps | null => {
  const entry = firstNonNullable(entrySingle);
  const parseEntryCards = createFragmentParser(ENTRY_CARDS_FRAGMENT, (data) => data);

  const { fragment } = useEntryCards(parseEntryCards(entry)) ?? {};
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, fragment);
  const maybeHeading = toStringOrNull(heading);
  const maybeContent = cleanHtml(content);
  const maybeLink = parseLinkField(linkField);

  const maybeColorSet = maybeGet(fragment, 'colorScheme');
  const maybeProgram = maybeGet(fragment, 'programCategory');
  const programCategory = getFragmentData(
    PROGRAM_CATEGORY_FRAGMENT,
    firstNonNullable(excludeEmptyTypedElements(maybeProgram))
  );

  const colorSet = parseColorScheme(maybeColorSet);
  const programColorSet = parseColorScheme(programCategory?.colorScheme);

  // Only bail out if all fields are empty except for maybeContent
  if (!fragment && !maybeHeading && !maybeLink) return null;

  const isFAQ = fragment?.__typename === 'faq_Entry';

  const title = isFAQ ? toString(fragment.heading) : maybeHeading ?? toString(fragment?.entryTitle);
  const entrySummary = isFAQ ? fragment.content : maybeContent ?? fragment?.entrySummary;
  const entryImage = maybeGet(fragment, 'entryImage');
  const image = !!imageSingle.length ? imageSingle : entryImage;
  const href = maybeLink?.href ?? parseUri(base?.uri);

  return {
    item: { title, description: entrySummary, href, image },
    colorSet: programColorSet ?? colorSet,
  };
};
