'use client';

import { useEntryCards } from '@/components/EntryCards/useEntryCards';
import ExpandingCard from '@/components/ExpandingCard';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField } from '@/lib/parsers/common';
import { createFragmentParser } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri, toBoolean } from '@liquorice/utils';
import { gql } from '__generated__';

export const EXPANDING_CARD_BLOCK_FRAGMENT = gql(`
  fragment expandingCardBlock on expandCard_Entry {
    entrySingle {
      ...entryCards
    }
    
    # Override props
    flipAlignment
    heading
    content: htmlContentSimple
    imageSingle {
      ...image
    }
    linkField {
      ...linkField
    }
  }
`);

export const ExpandingCardBlock = createBlock(EXPANDING_CARD_BLOCK_FRAGMENT, ({ data }) => {
  const parseEntry = createFragmentParser(ENTRY_CARDS_FRAGMENT, (data) => data);
  const entry = parseEntry(firstNonNullable(data.entrySingle));
  const card = useEntryCards(entry);

  const { fragment } = card ?? {};
  const { heading, content, linkField, imageSingle } = data;

  const getBase = createFragmentParser(ENTRY_BASE_FRAGMENT, (data) => data);
  const base = getBase(card?.fragment);

  const link = parseLinkField(linkField);

  const href = link?.href ? link.href : parseUri(base?.uri);
  const flipAlignment = toBoolean(data.flipAlignment);
  const isFAQ = fragment?.__typename === 'faq_Entry';
  const entryImage = isFAQ ? null : fragment?.entryImage;

  const title = isFAQ ? fragment.heading : heading ?? fragment?.entryTitle;
  const description = isFAQ ? fragment.content : content ?? fragment?.entrySummary;
  const image = !!firstNonNullable(imageSingle) ? imageSingle : entryImage;

  return (
    <ExpandingCard
      {...{
        item: {
          title,
          description,
          image,
          href,
        },
        flipAlignment,
      }}
    />
  );
});

export default ExpandingCardBlock;
