import Flex, { FlexProps } from '@/components/ui/Flex';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { BlockMeta } from '@/lib/parsers/blocks';
import { mergePropsClassName } from '@liquorice/utils';
import React from 'react';
import * as style from './BlockHeader.css';

export type BlockHeaderProps = FlexProps<
  'div',
  {
    children?: React.ReactNode;
    className?: string;
    heading?: React.ReactNode;
    HeadingProps?: TxtProps;
    content?: React.ReactNode;
    ContentProps?: TxtProps;
    meta?: BlockMeta;
  } & style.BlockHeaderVariants
>;

const BlockHeader = ({
  children,
  heading,
  HeadingProps,
  content,
  ContentProps,
  maxWidth,
  meta: _meta,
  ...props
}: BlockHeaderProps) => {
  return (
    (heading || content || children) && (
      <Flex alignItems="start" {...mergePropsClassName(props, style.root({ maxWidth }))}>
        {heading && (
          <Flex direction="row" alignItems="start" columnGap="xs">
            <Txt variant="h3" as="h2" autoMargin {...HeadingProps}>
              {heading}
            </Txt>
          </Flex>
        )}
        {content && (
          <Txt as="div" variant="body" html {...ContentProps}>
            {content}
          </Txt>
        )}
        {children}
      </Flex>
    )
  );
};

export default BlockHeader;
