import { BlockContainer } from '@/components/Blocks';
import Table from '@/components/Table';
import { createBlock } from '@/lib/parsers/blocks';
import { excludeEmptyTypedElements, firstNonNullable } from '@liquorice/utils';
import { gql } from '__generated__';

export const TABLE_BLOCK_FRAGMENT = gql(`
  fragment tableBlock on blocks_table_BlockType {
    id
    table {
      ...table
    }
  }
`);

const TableBlock = createBlock(TABLE_BLOCK_FRAGMENT, ({ data, meta }) => {
  if (!data) return null;

  const table = firstNonNullable(excludeEmptyTypedElements(data.table));

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      <Table data={table} />
    </BlockContainer>
  );
});

export default TableBlock;
