import * as richTextStyles from '@/components/Blocks/BlockComponents/RichTextBlock/RichTextBlock.css';
import FaqCardAccordion from '@/components/FaqCard/FaqCard.Accordion';
import FaqCardAccordionItem from '@/components/FaqCard/FaqCard.AccordionItem';
import Box from '@/components/ui/Box';
import Txt from '@/components/ui/Txt';
import { FAQ_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createBlock } from '@/lib/parsers/blocks';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { getFragmentData, gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';

export const FAQ_CARDS_BLOCK_FRAGMENT = gql(`
  fragment faqCardsBlock on blocks_faqCards_BlockType {
    heading
    faqMultiple {
      ...faqCard
    }
  }
`);

export const FaqCardsBlock = createBlock(FAQ_CARDS_BLOCK_FRAGMENT, ({ data, meta }) => {
  const faqMultiple = excludeEmptyTypedElements(data.faqMultiple);
  const faqCards = getFragmentData(FAQ_CARD_FRAGMENT, faqMultiple);

  return (
    <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
      <FaqCardAccordion>
        {faqCards.map((props, index) => (
          <FaqCardAccordionItem
            key={props.heading}
            {...{
              index,
              LabelProps: {
                children: props?.heading,
                color: 'primary',
                variant: 'text',
              },
              BodyProps: {
                index,
                children: (
                  <Box cx={{ pX: 'sm' }}>
                    <Txt html className={richTextStyles.withRichText}>
                      {props?.content}
                    </Txt>
                  </Box>
                ),
              },
            }}
          />
        ))}
      </FaqCardAccordion>
    </BlockContainer>
  );
});

export default FaqCardsBlock;
