'use client';

import Card, { CardProps } from '@/components/Card';
import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import { ColorScheme, useColorScheme } from '@/lib/parsers/common';
import { useBreakpointMin } from '@/theme';
import { mergePropsClassName } from '@liquorice/utils';
import { useState } from 'react';
import * as styles from './ExpandingCard.css';

export type ExpandingCardProps = CardProps & {
  flipAlignment?: boolean;
  colorScheme?: ColorScheme;
};

const ExpandingCard = ({ item, flipAlignment, colorScheme, ...props }: ExpandingCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const { colorScheme: colorSet, oppositeScheme } = useColorScheme(colorScheme, 'secondary');
  const md = useBreakpointMin('md');

  return (
    <Card
      elevation={false}
      item={item}
      onMouseEnter={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      {...mergePropsClassName(props, styles.root)}>
      <Box className={styles.imageWrapper}>
        <Card.Image ShimProps={{ cx: { height: 'full' } }} />
      </Box>
      <Flex
        alignItems="start"
        colorSet={colorSet}
        paper={md}
        className={styles.content({ flipAlignment })}>
        <Card.Title monoFont uppercase />
        {(hovered || !md) && <Card.Description autoMargin />}
        <Card.Cta
          color={md ? oppositeScheme : undefined}
          underline="always"
          endIcon="chevronRight"
        />
      </Flex>
    </Card>
  );
};

export default ExpandingCard;
