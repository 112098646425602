'use client';

import { CATEGORIES_FRAGMENT, VENUE_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { EVENT_ENTRY_FRAGMENT } from '@/gql/fragments/entries.gql';
import { parseEventTimeFragment } from '@/lib/parsers/common/parseEventTime';
import { useAppContext } from '@/lib/store/hooks';
import { fmtDate } from '@/lib/utils/format';
import { useBreakpointMax } from '@/theme';
import { createFragmentParser } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';
import { Dialog, DialogTitle } from '@mui/material';
import { EventCardFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import IconButton from '../IconButton';
import Box, { BoxProps } from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Flex from '../ui/Flex';

type PageTicketProps = BoxProps<
  'div',
  {
    TicketBtnProps?: BtnProps;
    parentEvent?: EventCardFragment;
  }
>;

export default function PageTicket({
  children,
  parentEvent,
  TicketBtnProps,
  ...props
}: PageTicketProps) {
  const [open, setOpen] = useState(false);
  const t = useTranslations();
  const { data: page } = useAppContext() ?? {};
  const sm = useBreakpointMax('sm');

  const parseEvent = createFragmentParser(EVENT_ENTRY_FRAGMENT, (data) => data);

  const isEvent = page?.__typename === 'event_Entry';
  const event = isEvent ? parseEvent(page) : null;

  const { venueCategory, eventId, eventTime, status } = parentEvent ?? event ?? {};

  const times = parseEventTimeFragment(eventTime);

  const getCategory = createFragmentParser(CATEGORIES_FRAGMENT, (data) => data);
  const parseVenue = createFragmentParser(VENUE_CATEGORY_FRAGMENT, (data) => data);
  const category = getCategory(firstNonNullable(venueCategory));
  const venue =
    category?.__typename === 'venueCategory_Category' ? parseVenue(category) : undefined;

  const expired = status === 'expired';
  const disabled = expired || times?.length <= 0;

  const content = expired
    ? t('entryIndex.thisIsAnArchived') + ' ' + t('entryIndex.event')
    : t('actions.buyTickets');

  return (
    <>
      <Box {...props}>
        <Btn
          color={expired ? 'error' : 'primary'}
          disabled={disabled}
          size="large"
          weight="medium"
          startIcon={!expired ? 'ticket' : undefined}
          fullWidth
          onClick={() => setOpen(true)}
          {...TicketBtnProps}>
          {content}
        </Btn>
      </Box>
      <Dialog
        fullScreen={sm}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        PaperProps={{ sx: { width: '100%' } }}
        open={open}>
        <Flex direction="row" justifyContent="between">
          <DialogTitle width="100%">
            {t('actions.selectPerformance', { type: parentEvent?.entryTitle })}
          </DialogTitle>
          <Box cx={{ pX: 'md', pY: 'xs' }}>
            <IconButton icon="close" onClick={() => setOpen(false)} />
          </Box>
        </Flex>
        <Flex rowGap="xs" cx={{ pX: 'md', pB: 'xs' }}>
          {children ||
            times?.map((time, index) => {
              return (
                time && (
                  <Btn
                    key={index}
                    variant="outlined"
                    color="primary"
                    size="large"
                    target="_blank"
                    href={`https://tickets.geelongartscentre.org.au/WebPages/EntaWebGateway/gateway.aspx?E=N&QL=S${eventId}|V${venue?.venueShorthand}|P${time?.eventTimeId}|G~/WEBPAGES/EntaWebHtmlSeatPlan/HtmlSeatPlan.aspx`}>
                    {fmtDate(time.date)}
                  </Btn>
                )
              );
            })}
        </Flex>
      </Dialog>
    </>
  );
}
